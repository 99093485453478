<template>
  <div class="vehicle">
    <!-- 车辆管理 -->
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-input v-model="search" placeholder="请输入车牌号" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button type="primary" @click="handleAdd">新增</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" width="100">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="carNum" label="车牌号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="carName" label="车辆名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="carLoad" label="车辆载重">
            <template slot-scope="scope">
              <div>{{ scope.row.carLoad }} 吨</div>
            </template>
          </el-table-column>
          <el-table-column min-width="160" show-overflow-tooltip prop="createdTime" label="创建时间"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="160" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="" size="" plain @click="handleEdit(scope.$index, scope.row)">修改</el-button>
              <el-button class="btn" type="danger" size="" plain @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <el-dialog :title="isAdd ? '新增' : '修改'" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="车辆名称" prop="carName">
              <el-input v-model="ruleForm.carName" placeholder="请输入车辆名称"></el-input>
            </el-form-item>
            <el-form-item label="车辆载重" prop="carLoad">
              <el-input class="tons" v-model.number="ruleForm.carLoad" type="number" placeholder="请输入车辆载重">
                <template>
                  <div slot="suffix">单位/吨</div>
                </template>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="车牌号" prop="carNum">
              <el-input v-model.trim="ruleForm.carNum" placeholder="请输入车牌号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
function createInitRuleForm() {
  return {
    id: "",
    carNum: "",
    carName: "",
    carLoad: "",
  }
}
export default {
  data() {
    return {
      loading: false,
      search: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      dialogVisible: false,
      isAdd: true,
      ruleForm: createInitRuleForm(),
      rules: {
        carNum: [
          { required: true, message: "请输入车牌号", trigger: ["blur", "change"] },
          {
            pattern: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/,
            message: "车牌号不正确或非国内车牌，参照：京AXXXXX",
            trigger: "change",
          },
        ],
        carName: [{ required: true, message: "请输入车辆名称", trigger: "blur" }],
        carLoad: [{ required: true, message: "请输入车辆载重", trigger: "blur" }],
      },
      departmentList: [],
      roleList: [],
    }
  },
  created() {
    this.getSupplierDeliveryCarList()
  },
  methods: {
    // 列表
    getSupplierDeliveryCarList() {
      this.loading = true
      this.$axios
        .get(this.$api.supplierDeliveryCarList, {
          params: {
            carNum: this.search,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 搜索
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getSupplierDeliveryCarList()
    },
    // 新增（对话框）
    handleAdd() {
      this.isAdd = true
      this.dialogVisible = true
    },
    // 修改（对话框）
    handleEdit(index, row) {
      this.isAdd = false
      this.$axios
        .get(this.$api.supplierDeliveryCarQuery, {
          params: {
            id: row.id,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.ruleForm = res.data.result
            this.dialogVisible = true
          } else {
            this.dialogVisible = false
          }
        })
        .catch(() => {
          this.dialogVisible = false
        })
    },
    // 删除
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该人员, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.supplierDeliveryCarDelete, {
              id: row.id,
            })
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success(res.data.desc)
              }
              this.getSupplierDeliveryCarList()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getSupplierDeliveryCarList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getSupplierDeliveryCarList()
    },
    // 关闭对话框
    onCancel() {
      this.dialogVisible = false
      this.resetForm()
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let api = this.isAdd ? this.$api.supplierDeliveryCarAdd : this.$api.supplierDeliveryCarEdit
          this.$axios.post(api, this.ruleForm).then(res => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc)
              this.getSupplierDeliveryCarList()
              this.dialogVisible = false
              this.resetForm()
            }
          })
        } else {
          this.$message.error("提交失败")
          return false
        }
      })
    },
    // 重置校验
    resetForm() {
      this.ruleForm = createInitRuleForm()
      this.$refs.ruleForm.resetFields()
    },
  },
}
</script>

<style scoped lang="scss">
.vehicle {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;

      /deep/ .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .tons {
    /deep/.el-input__inner {
      padding-right: 65px;
    }
  }

  .disabled_user_account {
    width: 255px;
    color: #c0c4cc;
    padding: 0 15px;
    border-radius: 10px;
    background-color: #f5f7fa;
    box-sizing: border-box;
    cursor: not-allowed;
  }
}
</style>
